<template>
  <div class="cardBox" :style="{ backgroundImage: 'url(' + bgimg + ')' }">
    <div class="message">
      <al-image
        class="img"
        :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
        fit="cover"
      ></al-image>
      <div class="div">
        <h1 class="textOverflow" style="width: 300px">
          {{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}
        </h1>
        <small class="textOverflow" style="width: 300px">{{
          item.job_title_en | priorFormat(item.job_title_zh, LOCALE)
        }}</small>
        <span
          v-if="item.foreign_info && item.foreign_info.Industry_id"
          class="textOverflow"
          style="width: 300px"
          :title="item.foreign_info.Industry_id | industryFormat(LOCALE)"
        >
          {{ item.foreign_info.Industry_id | industryFormat(LOCALE) }}</span
        >
        <span
          v-else
          class="textOverflow"
          style="width: 300px"
          :title="item.industry | industryFormat(LOCALE)"
        >
          {{ item.industry | industryFormat(LOCALE) }}</span
        >
      </div>
    </div>
    <p
      class="dsc textOverflow"
      v-if="item.foreign_info && item.foreign_info.name_en"
      :title="
        item.foreign_info.name_en
          | priorFormat(item.foreign_info.name_zh, LOCALE)
      "
    >
      {{
        item.foreign_info.name_en
          | priorFormat(item.foreign_info.name_zh, LOCALE)
      }}
    </p>
    <p
      class="dsc textOverflow"
      v-else
      :title="item.company_name_en | priorFormat(item.company_name_zh, LOCALE)"
    >
      {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
    </p>
    <div
      class="infoBox"
      v-if="
        item.is_follow == 1 || !USER_INFO.id || item.user_id == USER_INFO.id
      "
    >
      <div>
        <img :src="phoneImg" alt="" />
        <span class="line_1" v-if="USER_INFO.id">
          {{ item.mobile | textFormat }}</span
        >
        <span v-else class="cursor line_1" @click="$router.push('/sign')">{{
          $t("yetLogin_see")
        }}</span>
      </div>
      <div>
        <img :src="emailImg" alt="" />
        <span class="line_1" v-if="USER_INFO.id">{{
          item.email | textFormat
        }}</span>
        <span v-else class="cursor line_1" @click="$router.push('/sign')">{{
          $t("yetLogin_see")
        }}</span>
      </div>
      <div class="address">
        <img :src="location_png" alt="" />
        <div
          class="line_2"
          v-if="item.foreign_info && item.foreign_info.country"
          :title="
            item.foreign_info.country
              | countryCityFormat(
                item.foreign_info.region_en,
                item.foreign_info.region_zh,
                LOCALE
              )
          "
        >
          {{
            item.foreign_info.country
              | countryCityFormat(
                item.foreign_info.region_en,
                item.foreign_info.region_zh,
                LOCALE
              )
          }}
          <span
            v-if="item.foreign_info.address_en || item.foreign_info.address_zh"
            :title="
              item.foreign_info.address_en
                | priorFormat(item.foreign_info.address_zh, LOCALE)
            "
            >{{
              item.foreign_info.address_en
                | priorFormat(item.foreign_info.address_zh, LOCALE)
            }}</span
          >
        </div>
        <div
          class="line_2"
          v-else
          :title="
            item.country
              | countryCityFormat(item.region_en, item.region_zh, LOCALE)
          "
        >
          {{
            item.country
              | countryCityFormat(item.region_en, item.region_zh, LOCALE)
          }}
          <span
            v-if="item.address_en || item.address_zh"
            :title="item.address_en | priorFormat(item.address_zh, LOCALE)"
            >{{ item.address_en | priorFormat(item.address_zh, LOCALE) }}</span
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="isDetail" class="tc isDetail">
        {{ $t("getContact") }}
      </div>
      <div v-else>
        <div class="infoBtn tc" v-if="item.is_follow == 2">
          {{ $t("sentApplyFor") }}
        </div>
        <div
          class="infoBtn mainColor tc cursor"
          v-if="item.is_follow == 0"
          @click="sendChange"
        >
          {{ $t("getContact") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import alImage from "../alImage.vue";
export default {
  components: { alImage },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bgimg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/bg_blue.png",
      phoneImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/phone.png",
      emailImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/email.png",
      location_png:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/location_png.png",
    };
  },
  methods: {
    sendChange() {
      this.ExchangeCard();
    },
    //  交换名片
    async ExchangeCard() {
      if (this.USER_INFO && !this.USER_INFO.company_id) {
        this.$message.warning(this.$t("informationCompany"));
        return;
      }
      let params = {
        company_id: this.item.company_id,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
        followed_user_id: this.item.user_id,
        follow_type: 2,
        source: this.PJSource,
      };

      let exchangeCard = await this.$store.dispatch(
        "baseStore/exchangeCard",
        params
      );
      if (exchangeCard && exchangeCard.success) {
        this.$emit("excardSuccess");
        this.$message.success(this.$t("sendSuccess"));
      }
      if (exchangeCard && exchangeCard.message == "already exist") {
        this.$message.warning(this.$t("card_exit"));
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cardBox {
  background-size: contain;
  background-repeat: no-repeat;
  height: 240px;
  width: 420px;
  padding: 15px;
  margin: 0 auto;
  .message {
    height: 80px;
    display: flex;
    .img {
      width: 90px !important;
      height: 72px !important;
      border-radius: 50%;
      overflow: hidden;
    }
    .div {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      small {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #a6d1ff;
        line-height: 22px;
      }
      span {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #f4faff;
        line-height: 24px;
      }
    }
  }
  .dsc {
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .infoBtn {
    width: 320px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #ececee;
    color: #999;
    margin: 0 auto;
    border-radius: 5px;
    line-height: 32px;
    margin-top: 30px;
  }
  .isDetail {
    color: #ececee;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 12px 0;
  }
  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      display: flex;
      align-items: flex-start;
      img {
        padding-right: 9px;
      }
      .line_1 {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
      }
      .line_2 {
        cursor: pointer;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>